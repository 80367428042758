.HOME {
    width: 100%;
    // overflow: hidden;
    .landingwrapper {
        width: 100%;
        height: 100vh;

        .imagewrapper {
            width: 100%;
            height: 100%;
            background-color: black;
            overflow: hidden;
            position: relative;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-image: url("../assets/imgs/landing_background_4.jpg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position-y: center;
                background-position-x: center;
                position: absolute;
                // transform-origin: 50% 62%;
                // transform-origin: 51% 75%;
                transform-origin: 46% 75%;
                opacity: .2;

                .grayscalecover {
                    // background-color: rgba(0, 0, 0, 0.7);
                    position: absolute;
                    width: 100%;
                    height:  100%;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
    .intowrapperwrapper {
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        .introwrapper {
            height: 80%;
            max-width: var(--max-content-width);
            margin: 0 auto;
            margin-top: 10%;
    
            .HelloItsMeWrapper {
                color: var(--white);
                font-weight: 900;
                font-size: var(--subheading-landing);
            }
            .NameWrapper {
                color: var(--white);
                font-weight: 900;
                font-size: var(--heading-landing);
    
                .dot {
                    color: var(--accent-color);
                    
                }
            }
            .IAmAWrapper {
                color: var(--slate);
                font-weight: 900;
                font-size: var(--subheading-landing-2);
                margin-top: 25px;
    
                .underlineWrapper {
                    background-color: var(--grad)
                }
            }
            .jumpingArrowWrapper {
                
                position: absolute;
                bottom: 0;
                width: min(100%, 1250px);
                .jumpingArrow {
                    width: 75px;
                    height: 75px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 100px;
    
                    width: 3rem;
                    height: 3rem;
                    margin-top: 2rem;
                    display: flex; 
                    color: var(--accent-color);
                    border-radius: 50%;
                    border: 2px solid var(--accent-color);
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
    
                }
                .jumpingArrow:hover {
                    background-color: var(--accent-color);
                    cursor: pointer;
    
                    .actualarrow {
                        color: black;
                    }
                }
            }
        }
    }
    .transitionscreen {
        height: 100vh;
        width: 100%;
        // background-image: linear-gradient(to right,black, #101523, black);
        // background-color: 2596be;
        // background-color: black;
        // background-color: #0d121c;
    }
    .maxwidthcenterwrapper {
        max-width: var(--max-content-width);
        margin: 0 auto;
        padding-left: 30px;
        padding-right: 30px;
    }
    .aboutmetitle {
        color: white;
        font-size: 1.5rem !important;
    }
    .aboutmescreen {
        background: linear-gradient(to bottom, #0d121c, black);
        min-height: 100vh;

        .buildemphasis {
            color: var(--accent-color);
        }
        .aboutmefulltext {
            color: var(--slate);
        }
    }


    .aboutmemainscreen {
        min-height: 100vh;  
         
    }

    .myexperiencescreen {
        background-color: red;
        position: relative;
    }

    .panel-rollover-1 {
        position: relative;
        min-height: 100vh;
        z-index: 5;

    }
    .panel-rollover-1-inner {
        width: 100%;
        position: relative;
        min-height: 200vh;
    }
    .panel-rollover-1-fixed .panel-rollover-1-inner {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        overflow-y:scroll;
    }
    .panel-rollover-1-fixed {
        z-index: 1;
        overflow-y:scroll;
    }


}
.awardsscreen {
    min-height: 100vh;
}

.educationscreen {
    .timelimetext {
        color: var(--slate);
    }
    background-image:         linear-gradient(to bottom, black 0%, var(--navy) 100vh);
    min-height: 50vh;
    .educationitemwrapper {
        
    }
}

.experiencescreen {
    min-height: 100vh;  
}
/****************************************************
Education CSS
****************************************************/

.educationscreen {
    .educationactivity {
        line-height: 23px;
    }
}

/****************************************************
Experiences CSS
****************************************************/

.experiencescreen {
    background-color: var(--navy);
    min-height: 50vh;
}
  
  @font-face {
    font-family: 'ESL Legend';
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.eot);
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Light.svg#eicon) format("svg");
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: 'ESL Legend';
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.eot);
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Regular.svg#eicon) format("svg");
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'ESL Legend';
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.eot);
    src: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.eot#iefix) format("embedded-opentype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.woff2) format("woff2"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.woff) format("woff"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.ttf) format("truetype"),
    url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/fonts/legend/ESLLegend-Bold.svg#eicon) format("svg");
    font-style: normal;
    font-weight: bold;
  }
  
  img {
    max-width: 100%;
  }
  
  .ag-format-container {
    width: 1160px;
    margin: 0 auto;
  
    position: relative;
  }
  
  .ag-timeline-block {
    padding: 300px 0;
  }
  .ag-timeline_title-box {
    padding: 0 0 30px;
  
    text-align: center;
  }
  .ag-timeline_tagline {
    font-size: 40px;
    color: rgb(84, 89, 95);
  }
  .ag-timeline_title {
    background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/timeline/images/bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    text-fill-color: transparent;
    color: transparent;
  
    font-size: 80px;
  }
  
  .ag-timeline_item {
    margin: 0 0 50px;
  
    position: relative;
  }
  .ag-timeline_item:nth-child(2n) {
    text-align: right;
  }
  
  .ag-timeline {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  
    position: relative;
  }
  .ag-timeline_line {
    width: 2px;
    background-color: #393935;
  
    position: absolute;
    top: 2px;
    left: 50%;
    bottom: 0;
  
    overflow: hidden;
  
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  
  .ag-timeline_line-progress {
    width: 100%;
    height: 20%;
    background-color: var(--accent-color);
  }
  
  .ag-timeline-card_box {
    padding: 0 0 20px 50%;
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
    padding: 0 50% 20px 0;
  }
  .ag-timeline-card_point-box {
    display: inline-block;
    margin: 0 14px 0 -28px;
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
    margin: 0 -28px 0 14px;
  }
  .ag-timeline-card_point {
    height: 50px;
    line-height: 50px;
    width: 50px;
    border: 3px solid var(--accent-color);
    background-color: #1d1d1b;
  
    text-align: center;
    font-family: 'ESL Legend', sans-serif;
    font-size: 20px;
    color: #FFF;
  
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
  .js-ag-active .ag-timeline-card_point {
    color: #1d1d1b;
    background-color: var(--accent-color);
  }
  .ag-timeline-card_meta-box {
    display: inline-block;
  }
  .ag-timeline-card_meta {
    // margin: 10px 0 0px;
    font-family: 'ESL Legend', sans-serif;
    font-weight: bold;
    font-size: 28px;
    color: var(--accent-color);
  }
  .ag-timeline-card_item {
    display: inline-block;
    width: 45%;
    margin: -77px 0 0;
    // background-color: #282828;
    // background-image:   linear-gradient(to bottom, black 50%, var(--dark-navy) );
    background-color: var(--light-navy);
  
    opacity: 0;
  
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
  
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
    -o-box-shadow: 0 0 0 0 rgba(0,0,0,.5);
    box-shadow: 0 0 0 0 rgba(0,0,0,.5);
  
    -webkit-transition: -webkit-transform .5s, opacity .5s;
    -moz-transition: -moz-transform .5s, opacity .5s;
    -o-transition: -o-transform .5s, opacity .5s;
    transition: transform .5s, opacity .5s;
  
    position: relative;
  }
  .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item {
    -webkit-transform: translateX(-200%);
    -moz-transform: translateX(-200%);
    -ms-transform: translateX(-200%);
    -o-transform: translateX(-200%);
    transform: translateX(-200%);
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    -webkit-transform: translateX(200%);
    -moz-transform: translateX(200%);
    -ms-transform: translateX(200%);
    -o-transform: translateX(200%);
    transform: translateX(200%);
  }
  .js-ag-active.ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item,
  .js-ag-active.ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
    opacity: 1;
  
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .ag-timeline-card_arrow {
    height: 18px;
    width: 18px;
    margin-top: 20px;
    background-color: #282828;
  
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
  
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_arrow {
    margin-left: calc(-18px / 2);
    margin-right: calc(-18px / 2);
  }
  .ag-timeline_item:nth-child(2n) .ag-timeline-card_arrow {
    margin-left: -10px;
  
    right: auto;
    left: 0;
  }
  .ag-timeline-card_img {
    width: 100%;
    object-fit: cover;
  }
  .ag-timeline-card_info {
    padding: 20px 30px;
  }
  .ag-timeline-card_title {
    display: none;
    margin: 10px 0 0;
  
    font-family: 'ESL Legend', sans-serif;
    font-weight: bold;
    font-size: 28px;
    color: var(--accent-color);
  }
  .ag-timeline-card_desc {
    line-height: 1.45;
  
    font-size: 16px;
    color: #FFF;
  }
  
  
  @media only screen and (max-width: 979px) {
    .ag-timeline_line {
      left: 30px;
    }
  
    .ag-timeline_item:nth-child(2n) {
      text-align: left;
    }
  
    .ag-timeline-card_box,
    .ag-timeline_item:nth-child(2n) .ag-timeline-card_box {
      padding: 0 0 20px;
    }
    .ag-timeline-card_meta-box {
      display: none;
    }
    .ag-timeline-card_point-box,
    .ag-timeline_item:nth-child(2n) .ag-timeline-card_point-box {
      margin: 0 0 0 8px;
    }
    .ag-timeline-card_point {
      height: 40px;
      line-height: 40px;
      width: 40px;
    }
    .ag-timeline-card_item {
      width: auto;
      margin: -65px 0 0 75px
    }
    .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_item,
    .ag-timeline_item:nth-child(2n) .ag-timeline-card_item {
      -webkit-transform: translateX(200%);
      -moz-transform: translateX(200%);
      -ms-transform: translateX(200%);
      -o-transform: translateX(200%);
      transform: translateX(200%);
    }
    .ag-timeline_item:nth-child(2n+1) .ag-timeline-card_arrow {
      right: auto;
      left: 0;
    }
    .ag-timeline-card_title {
      display: block;
    }
    .ag-timeline-card_arrow {
      margin-top: 12px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .ag-format-container {
      width: 96%;
    }
  
    .ag-timeline-card_img {
      height: auto;
      width: auto;
    }
  }
  
  @media only screen and (max-width: 639px) {
    .ag-timeline_title {
      font-size: 60px;
    }
  
    .ag-timeline-card_info {
      padding: 10px 15px;
    }
    .ag-timeline-card_desc {
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 479px) {
  
  }
  
  @media (min-width: 768px) and (max-width: 979px) {
    .ag-format-container {
      width: 750px;
    }
  
  }
  
  @media (min-width: 980px) and (max-width: 1161px) {
    .ag-format-container {
      width: 960px;
    }
  
  }
  .image_experience_cover {
    position: absolute;
    background: rgba(0, 0, 0, 0.85);
    width: 100%;
    top: 0;
  }
  .image_experience_text_wrapper {
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .image_experience_text {
    color: white;
    font-size: var(--text200);
    font-weight: var(--bold9);
    width: 100%;
    text-align: center;
    margin: 0 15px 0 15px;
  }
  .image_experience_dates {
   color: var(--slate);
   font-size: var(--text125); 
   font-weight: var(--bold5);
   text-align: center;
  }
  .image_experience_company {
    color: var(--accent-color);
    font-size: var(--text150);
    font-weight: var(--bold6);
    text-align: center;
  }
  .actualcompanyname {
    // padding-top: 100px;
    transform: translateY(-14px);
    display: inline-block;
  }